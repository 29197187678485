export const environment = {
  // Application details
  appName: "AHLUWALIA CONTRACTS (INDIA) LTD",
  appMail: "mail@acilnet.com",
  appContactNo: "+91 (011) 49410502",
  appAddress: "Plot No. A-177,Okhla Industrial Area, Phase-I,New Delhi-110020",
  appGSTN: "07AABCA4304K1ZZ",

  production: false,
  socketConfiguration: {

    // msHighCourtURL :"http://202.137.229.18/mscourt/mshc/api/public/",
    // msDistrictCourtURL : "http://202.137.229.18/mscourt/msec/api/public/",
    // msSupremeCourtURL: "http://202.137.229.18/mscourt/mssc/api/public/",

    //   msHighCourtURL :"http://192.168.0.161:8002/api/public/",
    //   msDistrictCourtURL : "http://192.168.0.161:8003/api/public/",
    //   msSupremeCourtURL: "http://192.168.0.161:8010/api/public/",

    // // CONSUMER FORUM URL
    // consumerForum:"http://192.168.0.161:8004/microservice/cfi/api/asgi/",

    // // TRIBUNAL URLL
    // tribunalUrl: "http://192.168.0.161:8007/microservice/tribunals/api/asgi/",

    // // DELHI HIGH COUT URL
    // msdelhihighcourtURL:"http://192.168.0.161:8001/microservice/hci/delhi/api/asgi/",

    // // BOMBAY HIGH COURT URL
    // bombayHighCourtUrl:"http://192.168.0.161:8006/microservice/hci/bombay/api/asgi/",

    // // ALLAHABAD HIGH COURT URL
    // AllahabadHighCourtUrl:"http://192.168.0.161:8008/microservice/hci/allahabad/api/asgi/",

    // SUPREME COURT url
    msSupremeCourtURL: "http://202.137.228.102:8010/api/public/",
    // HIGH COURT (E)
    msHighCourtURL: "http://202.137.228.102:8002/api/public/",
    // DISTRICT COURT (E) 
    msDistrictCourtURL: "http://202.137.228.102:8003/api/public/",
    // DELHI HIGH COUT URL
    msdelhihighcourtURL: "http://202.137.228.102:8001/microservice/hci/delhi/api/asgi/",
    // BOMBAY HIGH COURT URL
    bombayHighCourtUrl: "http://202.137.228.102:8006/microservice/hci/bombay/api/asgi/",
    // CONSUMER FORUM URL
    consumerForum: "http://202.137.228.102:8004/microservice/cfi/api/asgi/",
    // TRIBUNAL URLL
    tribunalUrl: "http://202.137.228.102:8007/microservice/tribunals/api/asgi/",
    // // ALLAHABAD HIGH COURT URL
    AllahabadHighCourtUrl: "http://202.137.228.102:8008/microservice/hci/allahabad/api/asgi/",
    // // Himachal HIGH COURT URL
    HimachalHighCourtUrl: "http://202.137.228.102:8009/microservice/hci/himachal/api/asgi/",
    // // Himachal HIGH COURT URL
    PunjabHighCourtUrl: "http://202.137.228.102:8011/microservice/hci/punjab/api/asgi/",
    // ncltCourt
    ncltCourt: "http://202.137.228.102:8015/microservice/nclt/api/",

    // msHighCourtURL :"http://192.168.0.161/mscourt/mshc/api/public/",
    // msDistrictCourtURL : "http://192.168.0.161/mscourt/msec/api/public/",
    // msSupremeCourtURL: "http://192.168.0.161/mscourt/mssc/api/public/",    

    // http://192.168.0.161:9006/microservice/hci/delhi/api/asgi/courtCases

    // msHighCourtURL :"https://mshc.mysita.com/api/public/",
    // msDistrictCourtURL : "https://msec.mysita.com/api/public/",
    // msSupremeCourtURL: "https://mssc.mysita.com/api/public/",
    // url: "https://ilexsys.server.soolegal.co.in:7001",
    // url: "http://148.66.131.203:9001",
    //url: "http://localhost:9001",
    //url: "http://148.66.131.203:9001", // Ilexsys Demo URL
    //url: "http://ec2-15-206-93-117.ap-south-1.compute.amazonaws.com:8822", //iLexSys
    //url: "http://ec2-15-206-93-117.ap-south-1.compute.amazonaws.com:8889", //Corporate v2 or ACIL's new(ACIL Live URL)
    url: "http://acillegal.mysita.com:8889", //Corporate v2 or ACIL's new(ACIL Live URL-On MDCS Server)
    
  },

  serviceConfiguration: {
    // url: "https://ilexsys.server.soolegal.co.in:7001",
    // url: "http://148.66.131.203:9001",
    //url: "http://localhost:9001",
    //url: "http://148.66.131.203:9001", // Ilexsys Demo URL
    // url: "http://ec2-15-206-93-117.ap-south-1.compute.amazonaws.com:8822", //iLexSys
    //url: "http://ec2-15-206-93-117.ap-south-1.compute.amazonaws.com:8889", //Corporate v2 or ACIL's new(ACIL Live URL)
    url: "http://acillegal.mysita.com:8889", //Corporate v2 or ACIL's new(ACIL Live URL-On MDCS Server)
    servicesEndPoints: {
      loginUser: {
        url: "/users/loginUser",
        method: "POST",
      },
      getOtpForLogin: {
        url: "/users/getOtpForLogin",
        method: "POST",
      },
      loginUserUsingOtp: {
        url: "/users/loginUserUsingOtp",
        method: "POST",
      },
      forgotPassword: {
        url: "/users/forgotPassword",
        method: "POST",
      },
      changePassword: {
        url: "/users/changePassword",
        method: "POST"
      },
      calenderListEvents: {
        url: "/calendarView/listEvents",
        method: "GET",
      },
      externalSharingCaseDetail: {
        url: "/external-sharing/case-details",
        method: "POST",
      },
    },
  },
  pagination: {
    defaultpageIndex: 0,
    defaultPageSize: 10,
    defaultPageSizeOptions: [5, 10, 20, 50],
  },

// ILEXSYS Custom Court Type
  custom_court_id_collection: {
    supreme_court_custom_id: '5f356bb7862bd4617bd3b529',
    high_court_custom_id: '6384a2c8a168f47632d70d36',
    district_court_custom_id: '5f356c0a862bd4617bd3b52b',
    tribunal_court_custom_ide_court: '5f6af11a0e20cb7da526736b',
    consumer_forum_court_custom_id: '63aacea0a6f6e33acdf39929',
  },

  socketEvents: {
    /* Users : Start */
    listUsers: "list_users",
    deletesupremeCourtCase: "",
    listUsersRoleUser: "list_users_role_user",
    listmyCourtCasesPagination: "list_mycourt_case_pagination",
    createarbitrationCase: "create_arbitration_case",
    listarbitrationPagination: "list_arbitration_pagination",
    getarbitrationDetails: "get_arbitration_detail",
    updatearbitraionCase: "update_arbitration",
    deletearbitration: "delete_arbitration",
    getassignedUsers: "get_assigned_user",
    changearbitrationCaseStatus: "change_arbitration_status",
    getHearingsForarbitration: "get_arbitration_hearing",
    savearbitrationDocument: "create_arbitration_petinor",
    savearbitrationRejoinerDocument: "create_rejoinder_document",
    savearbitrationSupportingDocument: "create_arbitrationsupport_document",
    savearbitrationSurrejoinerDocument: "create_arbitrationsurrejoiner",
    changearbitrationChearingStatus: "change_arbitration_hearing_status",
    createarbitrationAppointment: "create_arbitration_appointment",
    createcompanyprofile: "create_company_profile",
    createdistrictTemproryCourtCase: "create_district_temprory_case",
    listTemproryDistrictCourtCase: "list_temprory_district_court_cases",
    createDistrictpartyCourtCase: "create_district_party_court_cases",
    deletedistrictCourtCase: "delete_district_court_case",
    getCompanydetail: "get_company_detail",
    uploadcompanylogo: "upload_company_logo",
    uniquearbitratorValidation: "not_existing_arbitration",
    uploadshortlogo: "upload_short_logo",
    getCompanybydetail: "get_company_by_detail",
    updatearbitrationsHearing: "update_hearing_arbitration",
    getAppointmentsForarbitration: "get_appointmentfor_arbitration",
    deletePetitionarbitrationDocument: "delete_petioner_document",
    deletearbitrationRejoinderDocument: "delete_arbitration_rejoindre",
    savearbitrtionAdmissionDenialRespondentDocument: "save_arbitration_admission_denial_respondent",
    deleteSupportingarbitrationDocsDocuments: "delete_arbitration_supportdocument",
    deleteSurrejoinederarbitrationDocument: "delete_surrejoinder_arbitration",
    savearbitrationAdmissionDenialDocument: "save_arbitration_admission_denial",
    sharearbitrationmeetingUser: "share_arbitration_meeting",
    savearbitrationEvidenceDocument: "save_arbitration_evidence_document",
    savearbitrationEvidenceRespondentDocument: "save_arbitration_evidance_respondent_document",
    savearbitrationwritenArgumentDocument: "save_arbitration_written_argument_document",
    savearbitartionWritenArgumentRespondentDocument: "save_arbitration_respondent_document",
    deletearbitrationAdmissionDenialDocument: "delete_arbitration_admission_denial",
    deletearbitrationSupportingDocAdmissionDenialDocument: "delete_arbitration_support_admission_document",
    deletearbitrationEvidenceAffidavitDocument: "delete_arbitration_evidance_document",
    deletearbitrationSupportingDocEvidenceAffidavitDocument: "delete_arbitration_support_doc_evidance",
    deletearbitrationWrittenArgumentsDocuments: "delete_arbitration_written_argument_document",
    deletearbitrationSupportingDocsWrittenArgumentsDocuments: "delete_arbitration_support_wriiten_argument_document",
    listCourtCasesarbitrationAutoComplete: "auto_arbitration_auto_complete",
    createHighCourtCase: "create_high_court_case",
    createhighcourtpartyCourtCase: "create_highcourt_case_party",
    listhighcourtCasesPagination: "list_highcourt_case",
    listtribunalCasesPagination: "list_tribunal_case",
    consumerforumCasesPagination: "list_consumerforum_case",
    deletehighCourtCase: "delete_high_court_cases",
    createhighcourtTemproryCourtCase: "create_highcourt_ temprorycourt_cases",
    listTemproryhighCourtCase: "list_temprory_high_court_case",
    listarbitration: "list_of_arbitration",
    createarbitrationHearing: "create_arbitration_hearing",
    listDepartmentUsers: "list_department_user",
    createUser: "create_user",
    updateUser: "update_user",
    changePassword: "change_password",
    deleteUser: "delete_user",
    getLoggedInUserDetails: "get_logged_in_user_details",
    uniqueEmailValidation: "unique_email_validation",
    uniquePhoneValidation: "unique_phone_validation",
    listUsersPagination: "list_users_pagination",
    uniqueEmpIdValidation: "unique_emp_id_validation",
    oldPasswordValidation: "old_password_validation",
    findCourtCases: "find_Court_Cases",
    createTemproryCourtCase: "create_temprory_case",
    listTemproryCourtCase: "list_temprory_case",
    /* Users : End */

    /* Roles Management Sockets : Start */
    createRole: "create_role",
    deleteRole: "delete_role",
    updateRole: "update_role",
    listRoles: "list_roles",
    listRolesPagination: "list_roles_pagination",
    listModules: "list_modules",
    listRoleModules: "list_role_modules",

    /* Roles Management Sockets : End */

    /* Authentication : Start */
    authenticateUser: "authenticate_user",
    /* Authentication : End */

    /* Legal Notices Management : Start */
    createLegalDocFolder: "create_legal_doc_folder",
    updateLegalDocFolder: "update_legal_doc_folder",
    deleteLegalDocFolder: "delete_legal_doc_folder",

    listLinkforLegalDoc: "list_link_for_legal_doc",
    createLinkforLegalDoc: "create_link_for_legal_doc",
    deleteLinkforLegalDoc: "delete_link_for_legal_doc",
    cutCopyPasteLegalDoc: "cut_copy_paste_legal_doc",

    createLegalWordDoc: "create_legal_word_doc",
    updateLegalWordDoc: "update_legal_word_doc",
    autoSaveLegalWordDoc: "auto_save_legal_word_doc",
    deleteLegalWordDoc: "delete_legal_word_doc",
    createLegalNotice: "create_legal_notice",
    deleteLegalNotice: "delete_legal_notice",
    updateLegalNotice: "update_legal_notice",
    listLegalNotice: "list_legal_notice",
    listLegalNoticePagination: "list_legal_notice_pagination",
    getShareableLink: "get_shareable_link",
    getLegalNoticeDetails: "get_legal_notice_details",

    uploadNoticeDocumentSlice: "upload_notice_document_slice",
    uploadNoticeDocumentNext: "upload_notice_document_next",
    uploadNoticeDocumentEnd: "upload_notice_document_end",

    uploadOtherDocumentSlice: "upload_other_document_slice",
    uploadOtherDocumentNext: "upload_other_document_next",
    uploadOtherDocumentEnd: "upload_other_document_end",

    uploadReplyDocumentSlice: "upload_reply_document_slice",
    uploadReplyDocumentNext: "upload_reply_document_next",
    uploadReplyDocumentEnd: "upload_reply_document_end",
    /* Legal Notices Management : End */

    /* Designations Management Management : Start */
    createDesignation: "create_designation",
    deleteDesignation: "delete_designation",
    updateDesignation: "update_designation",
    listsDesignations: "list_designations",
    listsDesignationsPagination: "list_designations_pagination",
    /* Designations Management Management : End */

    /* Project Sites Management Sockets : Start */
    createProjectSite: "create_project_site",
    deleteProjectSite: "delete_project_site",
    updateProjectSite: "update_project_site",
    listsProjectSites: "list_project_sites",
    listsProjectSitesPagination: "list_project_sites_pagination",
    getApiRegionsProjects: "get_api_regions_projects",
    getModifiedApiRegionsProjects: "get_modified_api_regions_projects",

    /* Project Sites Management Sockets : End */

    /* Departments Management Sockets : Start */
    createDepartment: "create_department",
    deleteDepartment: "delete_department",
    updateDepartment: "update_department",
    listDepartments: "list_departments",
    listDepartmentsPagination: "list_departments_pagination",
    /* Departments Management Sockets : End */

    /* Divisions Management Sockets : Start */
    createDivision: "create_division",
    deleteDivision: "delete_division",
    updateDivision: "update_division",
    listsDivisions: "list_divisions",
    listsDivisionsPagination: "list_divisions_pagination",
    /* Divisions Management Sockets : End */

    /* Legal Docs Management Sockets : Start */
    createLegalDocs: "create_legal_docs",
    deleteLegalDocs: "delete_legal_docs",
    updateLegalDocs: "update_legal_docs",
    listLegalDocs: "list_legal_docs",
    getLegalDocDetails: "get_legal_doc_details",

    uploadLegalDocumentSlice: "upload_legal_document_slice",
    uploadLegalDocumentNext: "upload_legal_document_next",
    uploadLegalDocumentEnd: "upload_legal_document_end",
    /* Divisions Management Sockets : End */

    /* Advocates/Firm Sockets : Start */
    createAdvocateFirm: "create_advocate_firm",
    deleteAdvocateFirm: "delete_advocate_firm",
    updateAdvocateFirm: "update_advocate_firm",
    listAdvocateFirm: "list_advocate_firm",
    listAdvocateFirmPagination: "list_advocate_firm_pagination",
    /* Advocates/Firm Sockets : End */

    /* Mutual Cases Sockets : Start */
    shareMutualCaseExternalUser: "share_mutual_case_external_user",
    updateShareMutualCaseExternalUser: "update_share_mutual_case_external_user",

    createMutualCase: "create_mutual_case",
    updateShareMutualCaseInternalUser: "update_share_mutual_case_internal_user",
    shareMutualCaseInternalUser: "share_mutual_case_internal_user",
    deleteMutualCase: "delete_mutual_case",
    updateMutualCase: "update_mutual_case",
    listMutualCases: "list_mutual_cases",
    listMutualCasesPagination: "list_mutual_cases_pagination",
    changeMutualCaseStatus: "change_mutual_case_status",
    getMutualCaseDetails: "get_mutual_case_details",

    uploadMutualDocumentSlice: "upload_mutual_document_slice",
    uploadMutualDocumentNext: "upload_mutual_document_next",
    uploadMutualDocumentEnd: "upload_mutual_document_end",
    /* Mutual Cases Sockets : End */

    /* Court Cases Sockets : Start */
    createCourtCase: "create_court_case",
    createAutoCourtCase: "create_auto_court_case",
    createTribunal: "create_tribunal",
    createNclt: "create_nclt",
    tribunalCaseList: "tribunal_case_list",
    createbombayhighcourt: "create_bombayhighcourt",
    createdelhihighcourt: "create_delhihighcourt",
    createallahabadhighcourt: "create_allahabadhighcourt",
    createhimachalhighcourt: "create_himachalhighcourt",
    createpunjabhighcourt: "create_punjabhighcourt",
    createconsumercourt: "create_consumercourt",

    createConsumerforum: "create_consumerforum",

    listofretanership: "list_retainor_ship_model",
    createDistrictCourtCase: "create_district_court_case",
    listdistrictCourtCasesPagination: "list_districtcourt_case_petitioner_auto_complete",

    getCaseDetailsById: "get_case_details_by_id",
    shareCourtCaseInternalUser: "share_court_case_internal_user",
    updateShareCourtCaseInternalUser: "update_share_court_case_internal_user",

    shareCourtCaseExternalUser: "share_court_case_external_user",
    updateShareCourtCaseExternalUser: "update_share_court_case_external_user",

    deleteCourtCase: "delete_court_case",
    updateCourtCase: "update_court_case",
    listCourtCases: "list_court_case",
    listCourtCasesPeitionerAutoComplete:
      "list_court_case_petitioner_auto_complete",

    listCourtCasesPagination: "list_court_case_pagination",
    listCourtTypeAndCourtsAlias: "list_court_types_courts_alias",
    courtCasesForList: "court_cases_for_list",
    changeCourtCaseStatus: "change_court_case_status",
    getCourtCaseDetails: "get_court_case_details",
    getAssignedCourtCaseDetails: "get_assigned_court_case_details",
    getAssignedOutCourtCaseDetails: "get_assigned_out_court_case_details",
    getLinkedDoc: "get_linked_doc",
    uploadPetitionDocumentSlice: "upload_petition_document_slice",
    uploadPetitionDocumentNext: "upload_petition_document_next",
    uploadPetitionDocumentEnd: "upload_petition_document_end",
    uploadSupportingDocumentSlice: "upload_supporting_document_slice",
    uploadSupportingDocumentNext: "upload_supporting_document_next",
    uploadSupportingDocumentEnd: "upload_supporting_document_end",
    uploadOrdersDocumentSlice: "upload_orders_document_slice",
    uploadOrdersDocumentNext: "upload_orders_document_next",
    uploadOrdersDocumentEnd: "upload_orders_document_end",
    uploadMiscellaneousDocumentSlice: "upload_miscellaneous_document_slice",
    uploadMiscellaneousDocumentNext: "upload_miscellaneous_document_next",
    uploadMiscellaneousDocumentEnd: "upload_miscellaneous_document_end",
    uploadClaimDocumentSlice: "upload_claim_document_slice",
    uploadClaimDocumentNext: "upload_claim_document_next",
    uploadClaimDocumentEnd: "upload_claim_document_end",
    uploadRejoinerDocumentSlice: "upload_rejoiner_document_slice",
    uploadRejoinerDocumentNext: "upload_rejoiner_document_next",
    uploadRejoinerDocumentEnd: "upload_rejoiner_document_end",

    uploadAdmissionDenialDocSlice: "upload_admission_denial_doc_slice",
    uploadAdmissionDenialDocNext: "upload_admission_denial_doc_next",
    uploadAdmissionDenialDocEnd: "upload_admission_denial_doc_end",

    uploadEvidenceDocSlice: "upload_evidence_doc_slice",
    uploadEvidenceDocNext: "upload_evidence_doc_next",
    uploadEvidenceDocEnd: "upload_evidence_doc_end",

    uploadWrittenArgumentDocSlice: "upload_written_arg_doc_slice",
    uploadWrittenArgumentDocNext: "upload_written_arg_doc_next",
    uploadWrittenArgumentDocEnd: "upload_written_arg_doc_end",

    uploadRespClaimDocSlice: "upload_resp_claim_doc_slice",
    uploadRespClaimDocNext: "upload_resp_claim_doc_next",
    uploadRespClaimDocEnd: "upload_resp_claim_doc_end",

    uploadSurrejoinerDocSlice: "upload_surrejoiner_doc_slice",
    uploadSurrejoinerDocNext: "upload_surrejoiner_doc_next",
    uploadSurrejoinerDocEnd: "upload_surrejoiner_doc_end",

    uploadRespAdmissionDenialDocSlice:
      "upload_resp_adminssion_denial_doc_slice",
    uploadRespAdminssionDenialDocNext: "upload_resp_adminssion_denial_doc_next",
    uploadRespAdminssionDenialDocEnd: "upload_resp_adminssion_denial_doc_end",

    uploadRespEvidenceDocSlice: "upload_resp_evidence_doc_slice",
    uploadRespEvidenceDocNext: "upload_resp_evidence_doc_next",
    uploadRespEvidenceDocEnd: "upload_resp_evidence_doc_end",

    uploadRespWrittenArgDocSlice: "upload_resp_writtern_arg_doc_slice",
    uploadRespWrittenArgDocNext: "upload_resp_writtern_arg_doc_next",
    uploadRespWrittenArgDocEnd: "upload_resp_writtern_arg_doc_end",

    deleteDocuments: "delete_documents",
    saveUploadDocument: "save_upload_document",
    deleteSupportingDocument: "delete_supporting_document",
    savePetitionDocument: "save_petition_document",
    saveSupportingDocument: "save_supporting_document",
    saveRejoinerDocument: "save_rejoiner_document",
    saveSurrejoinerDocument: "save_surrejoiner_document",
    saveAdmissionDenialDocument: "save_admission_denial_document",
    saveEvidenceDocument: "save_evidence_document",
    saveEvidenceRespondentDocument: "save_evidence_respondent_document",
    savewritenArgumentDocument: "save_writen_argument_document",
    saveWritenArgumentRespondentDocument:
      "saveWriten_argument_respondent_document",
    saveletterDateDocument: "saveletter_date_document",
    saveAdmissionDenialRespondentDocument:
      "save_admission_denial_respondent_document",

    deleteWrittenStatementDocument: "delete_writen_statement_document",
    deletePetitionDocument: "delete_petition_document",
    deleteRejoinderDocument: "delete_rejoinder_document",
    deleteSurrejoinederDocument: "delete_surrejoineder_document",
    deleteSupportingDocsDocuments: "delete_supporting_docs_documents",
    deleteAdmissionDenialDocument: "delete_admission_denial_document",
    deleteSupportingDocAdmissionDenialDocument:
      "delete_supporting_doc_admission_denial_document",
    deleteEvidenceAffidavitDocument: "delete_evidence_affidavit_document",
    deleteSupportingDocEvidenceAffidavitDocument:
      "delete_Supporting_doc_evidence_affidavit_document",
    deleteWrittenArgumentsDocuments: "delete_written_arguments_documents",
    deleteSupportingDocsWrittenArgumentsDocuments:
      "delete_supporting_docs_written_arguments_documents",
    deleteLetterDateDocuments: "delete_letter_date_documents",
    deleteLegalNoticeDocuments: "delete_legal_notice_documents",
    deleteLinkedDocument: "delete_linked_document",

    getCourtStates: "get_court_states",
    getCourtCity: "get_court_city",
    /* Court Cases Sockets : End */

    /* Cases Abbreviation Sockets : Start */
    createCaseAbbreviation: "create_case_abbreviation",
    deleteCaseAbbreviation: "delete_case_abbreviation",
    updateCaseAbbreviation: "update_case_abbreviation",
    listCaseAbbreviation: "list_case_abbreviation",
    listCaseAbbreviationPagination: "list_case_abbreviation_pagination",
    /* Cases Abbreviation Sockets : End */

    /* Court Types Sockets : Start */
    createCourtType: "create_court_type",
    deleteCourtType: "delete_court_type",
    updateCourtType: "update_court_type",
    listCourtType: "list_court_type",
    listCourtTypePagination: "list_court_type_pagination",
    /* Court Types Sockets : End */

    /* Courts Sockets : Start */
    createCourt: "create_court",
    deleteCourt: "delete_court",
    updateCourt: "update_court",
    listCourt: "list_court",
    listCourtPagination: "list_court_pagination",
    listCourtsForCourtType: "list_courts_for_court_type",
    /* Courts Sockets : End */

    /* Bare Act Types Sockets : Start */
    createBareActType: "create_bare_act_type",
    deleteBareActType: "delete_bare_act_type",
    updateBareActType: "update_bare_act_type",
    listBareActType: "list_bare_act_type",
    listBareActTypePagination: "list_bare_act_type_pagination",
    /* Bare Act Types Sockets : End */

    /* Bare Acts Sockets : Start */
    createBareAct: "create_bare_act",
    deleteBareAct: "delete_bare_act",
    updateBareAct: "update_bare_act",
    listBareActs: "list_bare_acts",
    listBareActsPagination: "list_bare_acts_pagination",

    uploadOriginalDocumentSlice: "upload_original_document_slice",
    uploadOriginalDocumentNext: "upload_original_document_next",
    uploadOriginalDocumentEnd: "upload_original_document_end",

    uploadAmendmentDocumentSlice: "upload_amendment_document_slice",
    uploadAmendmentDocumentNext: "upload_amendment_document_next",
    uploadAmendmentDocumentEnd: "upload_amendment_document_end",
    /* Bare Acts Sockets : End */

    /* Appointments Sockets : Start */
    createAppointment: "create_appointment",
    deleteAppointment: "delete_appointment",
    updateAppointment: "update_appointment",
    listAppointment: "list_appointment",
    listAppointmentPagination: "list_appointment_pagination",
    markAppointmentCompleted: "mark_appointment_completed",
    getAppointmentsForCase: "get_appointments_for_case",
    /* Appointments Sockets : End */

    /* Tasks Management Sockets : Start */
    createTask: "create_task",
    deleteTask: "delete_task",
    updateTask: "update_task",
    markTaskCompleted: "mark_task_completed",
    listTask: "list_task",
    listTaskPagination: "list_task_pagination",
    getTaskDetails: "get_task_details",
    referToTask: "refer_to_task",

    uploadTaskDocumentSlice: "upload_task_document_slice",
    uploadTaskDocumentNext: "upload_task_document_next",
    uploadTaskDocumentEnd: "upload_task_document_end",
    /* Tasks Management Sockets : End */

    /* Hearings Sockets : Start */
    createHearing: "create_hearing",
    deleteHearing: "delete_hearing",
    updateHearing: "update_hearing",
    listHearings: "list_hearings",
    listHearingsPagination: "list_hearings_pagination",
    makeHearingCompleted: "mark_hearing_completed",
    getHearingsForCase: "get_hearings_for_case",
    getCompletedHearings: "get_completed_hearings",
    markedSeen: "marked_seen",
    /* Hearings Sockets : End */

    /* Judgements Sockets : Start */
    createJudgement: "create_judgement",
    deleteJudgement: "delete_judgement",
    updateJudgement: "update_judgement",
    listJudgements: "list_judgements",
    listJudgementsPagination: "list_judgements_pagination",

    uploadJudgementDocumentSlice: "upload_judgement_document_slice",
    uploadJudgementDocumentNext: "upload_judgement_document_next",
    uploadJudgementDocumentEnd: "upload_judgement_document_end",
    /* Judgements Sockets : End */

    /* Judgement Types Sockets : Start */
    createJudgementType: "create_judgement_type",
    deleteJudgementType: "delete_judgement_type",
    updateJudgementType: "update_judgement_type",
    listJudgementType: "list_judgement_type",
    listJudgementTypePagination: "list_judgement_type_pagination",
    /* Judgement Types Sockets : End */

    /* Discussion Board Sockets : Start */
    createDiscussionBoardListItem: "create_discussion_board_list_item",
    broadcastDiscussionBoard: "broadcast_discussion_board",
    broadcastDiscussionBoardDialog: "broadcast_discussion_board_dialog",
    createDiscussionCard: "create_discussion_card",
    updateDiscussionCard: "update_discussion_card",
    updateDiscussionCardmember: "update_discussion_card_member",
    getDiscussionDetails: "get_discussion_details",
    addDiscussionAttachment: "add_discussion_attachment",
    deleteDiscussionAttachment: "delete_discussion_attachment",
    addDiscussionComment: "add_discussion_comment",
    getarbitrationDiscussionDetails: "get_arbitration_discussion",
    /* Discussion Board Sockets : End */

    /* Dashboard Sockets : Start */
    getUpcomingEvent: "get_upcoming_events",
    getCasesCurrentMonth: "current_month_cases",
    dashboardStats: "dashboard_stats",
    stateWiseCasesNoticesForGraph: "state_wise_cases_notices_for_graph",
    inwardLegalNoticesReadUnreadStats: "inward_legal_notices_read_unread_stats",
    onGoingAndConcludedCasesForGraph: "on_going_concluded_cases_for_graph",
    caseTypeWiseStatsForGraph: "case_type_wise_stats_graph",
    casesNoticesDateWise: "cases_notices_date_wise",
    getToolbarStats: "get_toolbar_stats",
    /* Dashboard Sockets : End */

    /* Notificaitons Sockets : Start */
    broadcastNotificaitonChange: "broadcast_notification_change",
    listNotifications: "list_notifications",
    markAllNotificationRead: "mark_all_notification_read",
    markSpecificNotificationRead: "mark_specific_notification_read",
    notificationsCount: "notifications_count",
    /* Notificaitons Sockets : End */

    /* Billing Management Sockets : Start */
    createInvoice: "create_invoice",
    updateInvoice: "updated_invoice",
    markInvoicePaid: "mark_invoice_paid",
    getAdvocateCasesListPagination: "get_advocate_cases_list_pagination",
    getInvoices: "get_invoices",
    getInvoiceDetails: "get_invoice_details",
    getBillingCaseDetails: "get_billing_case_details",
    /* Billing Management Sockets : End */

    /* Report Management : Start*/
    listReportType: "list_report_type",
    assigneUserForDashbordReport: "assigne_user_for_dashbord_report",
    listReports: "list_reports",
    generateReports: "generate_eports",
    reportSend: "report_send",

    /* Report Management : End */

    /* Common Sockets : Start */
    getCities: "get_cities",
    getStates: "get_states",
    getCountries: "get_countries",
    getIndiaStates: "get_india_countries",
    getCountiesStatesCities: "get_countries_states_cities",
    logoutUser: "logout_user",
    /* Common Sockets : End */

    /* Inward Notice Dropbox Sockets : Start */
    createInwardNotice: "create_inward_notice",
    deleteInwardNotice: "delete_Inward_notice",
    updateInwardNotice: "update_inward_notice",
    referToInwardNotice: "refer_to_inward_notice",
    listInwardNotice: "list_inward_notice",
    listInwardNoticePagination: "list_inward_notice_pagination",
    createUpdateInwardDefaultValConfig:
      "create_update_inward_default_val_config",
    getDefaultValueConfig: "get_default_value_config",

    uploadInwardDocumentSlice: "upload_inward_document_slice",
    uploadInwardDocumentNext: "upload_inward_document_next",
    uploadInwardDocumentEnd: "upload_inward_document_end",
    /* Inward Notice Dropbox Sockets : End */

    /* Government Notification Sockets : Start */
    createGovernmentNotification: "create_government_notification",
    deleteGovernmentNotification: "delete_government_notification",
    updateGovernmentNotification: "update_government_notification",
    listGovernmentNotification: "list_government_notification",
    listGovernmentNotificationPagination:
      "list_government_notification_pagination",

    uploadGovernmentNotificationDocumentSlice:
      "upload_government_notification_document_slice",
    uploadGovernmentNotificationDocumentNext:
      "upload_government_notification_document_next",
    uploadGovernmentNotificationDocumentEnd:
      "upload_government_notification_document_end",
    /* Government Notification Sockets : End */
  },

  constants: {
    mutualCase: "mutual_case",
    courtCase: "court_case",
    arbitration: "arbitration",
    caseTypeFilter: {
      mutualCase: "mutual-case",
      courtCase: "court-case",
      legalNotice: "legal-notices",
      Arbitration: "arbitration"
    },
    natureOfCase: {
      civilMatter: {
        petitionerName: "Plaintiff",
        responderName: "Defendent",
        name: "Civil",
        value: "613f6a538d14d9d88e6e57bc",
      },
      criminal: {
        petitionerName: "Claimant",
        responderName: "Respondent",
        name: "Criminal",
        value: "613f6a538d14d9d88e6e57bf",
      },
      arbitration: {
        petitionerName: "Complainent",
        responderName: "Accused",
        name: "Arbitration",
        value: "5f3cece7871ae1381456cfdf",
      },
      other: { petitionerName: "Petitioner", responderName: "Respondent" },
    },
  },

  routes: {
    userProfile: "/app/user-route",
    rolesManagement: {
      main: "/app/roles",
    },
    usersManagement: {
      main: "/app/users",
    },
    advocateManagement: {
      main: "/app/advocates",
    },
    firmManagement: {
      main: "/app/firms",
    },
    caseAbbreviationManagement: {
      main: "/app/case-abbreviation",
    },
  },
  currentUserRole: "admin",
  roleDetails: {
    name: "",
    is_custom_role: true,
    allowedAccesses: {},
  },

  permissionLevels: {
    viewOnly: {
      key: "view_only",
      name: "View Only",
    },
    viewOnlyWithFiles: {
      key: "view_only_with_files",
      name: "View Only With Files",
    },
    editable: {
      key: "editable",
      name: "Editable",
    },
    editableWithFiles: {
      key: "editable_with_files",
      name: "Editable with Files",
    },
  },

  permissionLevelsExternalUser: {
    viewOnly: {
      key: "view_only",
      name: "View Only",
    },
    viewOnlyWithFiles: {
      key: "view_only_with_files",
      name: "View Only With Files",
    },
  },
  permissionLevelsKeysName: {
    view_only: "View Only",
    view_only_with_files: "View Only With Files",
    editable: "Editable",
    editable_with_files: "Editable with Files",
  },
  inwardNoticeRoleId: "607299e49234633a28e69030",
};
